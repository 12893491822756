<template>
<div>
   
  <div class="card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Loglar</span>

        <span class="text-muted mt-1 fw-bold fs-7">Toplam <b>{{this.groups.length}}</b> adet kayıt</span>
      </h3>
    </div>
    <!--end::Header-->

    <div class="card-body">
        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr class="fw-bolder">
                  <th>Tarih</th>
                  <th>Grup adı</th>
                  <th>Durum</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item,i) in groups" :key="i">
                  <td>{{item.date}}</td>
                  <td>{{item.userGroupName}}</td>
                  <td>{{item.status}}</td>
                </tr>
              </tbody>

              
            </table>
    </div>
   
  </div>


</div>
</template>

<script>

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import labels from "@/core/config/labels.js"
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from 'moment'
import api from '@/core/services/ApiService'
import Paging from '@/components/BD/Paging.vue';


export default {
  components: { Paging },
  name: "GroupLogs",
  props:['profile','isAdmin'],
  data(){
      return {
        groups:[],
          recordcount:0,
          paymentTypes:labels.paymentTypes,
          callStatusses:labels.callStatusses,
          pagelength:30,
          page:1,
          availablePackages:null,
          packageId:null,
      }
  },
  watch:{
      page:function(){
        this.getPackages();
      }
    },

  methods:{

      removePackage(selectedPackage){
        

        Swal.fire({
          text:'Kullanıcının paketini iptal etmek istediğinize emin misiniz',
          showCancelButton:true,
          icon:'warning',
          cancelButtonText:'Hayır',
          confirmButtonText:'Evet',
        }).then((res)=>{
          if(res.isConfirmed){
            api.get('/Package/CancelPackage/'+selectedPackage.purchaseId).then((res)=>{
              document.location.reload();
            })
          }  
        })
        
        

      },


      leftDay(expireDate){
          let today = new Date();
          return moment(expireDate).diff(today,'days');
      },
      moment(date){
          return moment(date)
      },

      next(){
        this.page++;
      },

      prev(){
        if(this.page>1){
          this.page--;
        }
      },

      gotoPage(pageNo){
        this.$router.push('?page='+pageNo);        
        this.page = pageNo;
      },

      

     


  },
  mounted(){
    
      setCurrentPageBreadcrumbs("Loglar", ["Kullanıcılar", this.profile.name+' '+this.profile.surname]);

       api.get('/User/UserGroupLog/'+this.profile.userId).then((res)=>{
        console.log(res.data);
        this.groups = res.data;
        
      })
     
    
  }
  
}
</script>
