<template>
   <ul class="pagination pagination m-0 float-right" :class="pageCount>10 ? 'pagination-groupped' : 'pagination-link'">
       
        <li class="page-item" v-if="page>1"><a class="page-link" href="#" @click.prevent="prev">&laquo;</a></li>
        
        
        <li v-for="pageNo in pageCount" class="page-item page-number-item" :class="page==pageNo ? 'page-item active' :''" :key="pageNo">
            <a class="page-link" @click.prevent="gotoPage(pageNo)" href="#">{{pageNo}}</a>
        </li>
        <li class="page-item" v-if="page<pageCount"><a class="page-link" href="#" @click.prevent="next">&raquo;</a></li>
    </ul>
</template>

<script>
export default {
    props:['recordcount','pagelength','page'],
    name:'Paging',

    data(){
        return {
            pageNumberItems: []      
        }
    },
   

    methods:{
        next(){
            if(this.page<this.pageCount){
                this.$emit("next");
            }
            
        },
        prev(){
            if(this.page>1){
                this.$emit("prev");
            }
            
        },
        gotoPage(pageNo){
            this.$emit('gotoPage',pageNo);
        }
    },
    computed:{
        pageCount(){
            
            let pageCount =  Math.ceil(this.recordcount / this.pagelength);
            let clusterStart = ((this.page - 1) * 10) / 10;
            for(let i=0;i<pageCount;i++){
                this.pageNumberItems.push(i+1);
            }

            console.log(clusterStart);
            
            return pageCount;
        }
    }

    

}
</script>

