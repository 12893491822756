module.exports = {
    genders:{
        'M':'Erkek',
        'F':'Kadın'
    },

    statusses : {
        'AC':{label:'Aktif',class:'success'},
        'DA':{label:'Pasif',class:'danger'},
      },

      callTypes:{
          0:'Tümü',
          1:'Hemen Görüşme',
          10:'Randevulu Görüşme'          
      },

      callStatusses:{
          'JJ':{label:'Tümü',class:'primary'},
          'PE':{label:'Bekleyen',class:'danger'},
          'AC':{label:'Aktif',class:'warning'},
          'CA':{label:'İptal',class:'white'},
          'DA':{label:'Tamamlandı',class:'success'},
      },

      userActivities:{
          0:'Hepsi',
          1:'Kullanıcı Bilgileri',
          2:'Giriş Çıkışlar',
          3:'Görüntülü Görüşme İşlemleri',
          5:'Satın Alma İşlemleri',
          6:'Yönetim İşlemleri'
      },

      paymentTypes : {
          0:'Hepsi',
          1:'Kredi Kartı',
          2:'Paket',
          3:'Puan'
      },

      userTypes:{
          10:'Danışan',
          5:'Danışman',
          6:'Uzman Danışman',
          2:'Sistem Kullanıcısı'
      },

      consultantTypes:{
          5:'Hemen Görüşme Danışman',
          6:'Uzman Danışman'
      },

      sliderTypes:{
          1:'Onboarding',
          2:'Mobil Anasayfa',
          3:'Mobil Hakkımızda'
      }
}